<template>
  <v-container>
    <div class="d-block d-md-flex">
      <AppAside></AppAside>
      <div class="ps-5 page_content grow">
        <v-card class="mt-10 mb-10">
          <v-card-title> סטריף חדש</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-form ref="form">
              <v-row class="basic mt-2">
                <v-col cols="6" md="3" class="py-0">
                  <v-text-field
                    background-color="white"
                    outlined
                    dense
                    v-model="item.name"
                    @keydown="name_exist = false"
                    :rules="[(v) => !!v || 'שדה חובה', !name_exist || '']"
                  >
                    <template slot="label">
                      <strong class="red--text">*</strong>
                      שם מותג
                    </template>
                  </v-text-field>
                </v-col>

                <v-col cols="6" md="3" class="py-0">
                  <v-select
                    background-color="white"
                    outlined
                    dense
                    type="number"
                    :items="types"
                    v-model="item.type"
                    label="סוג סטריף"
                    @change="$refs.form.resetValidation()"
                  >
                  </v-select>
                </v-col>

                <v-col cols="6" md="3" class="py-0">
                  <v-text-field
                    background-color="white"
                    outlined
                    dense
                    v-model="item.sort"
                    label="סדר"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="6" md="3" class="py-0" v-if="item.type == 'smart'">
                  <v-combobox
                    v-model="item.sections"
                    :items="sections"
                    item-text="name"
                    item-value="id"
                    :rules="[(v) => !!v || 'חובה']"
                    outlined
                    dense
                    multiple
                  >
                    <template slot="label">
                      <strong class="red--text">*</strong>
                      קטגוריות
                    </template>
                  </v-combobox>
                </v-col>

                <v-col cols="6" md="3" class="py-0" v-if="item.type == 'smart'">
                  <v-select
                    background-color="white"
                    outlined
                    dense
                    :rules="[(v) => !!v || 'חובה']"
                    :items="products_types"
                    v-model="item.products_type"
                  >
                    <template slot="label">
                      <strong class="red--text">*</strong>
                      סוג סטריפ
                    </template>
                  </v-select>
                </v-col>

                <v-col cols="6" md="3" class="py-0" v-if="item.type == 'smart'">
                  <v-text-field
                    background-color="white"
                    outlined
                    dense
                    :rules="[(v) => !!v || 'חובה']"
                    v-model="item.count"

                  >
                    <template slot="label">
                      <strong class="red--text">*</strong>
                      מספר שימוש
                    </template>
                  </v-text-field>
                </v-col>

                <v-col cols="6" md="3" class="py-0">
                  <v-text-field
                    background-color="white"
                    readonly
                    outlined
                    dense
                    label="סטטוס"
                    :value="item.status ? 'פעיל' : 'לא פעיל'"
                  >
                    <template slot="append">
                      <v-switch
                        v-model="item.status"
                        class="mt-0"
                        hide-details
                      ></v-switch>
                    </template>
                  </v-text-field>
                </v-col>

                <v-col cols="12" v-if="item.type == 'classic'">
                  <v-col
                    class="d-flex px-0"
                    cols="12"
                    sm="6"
                    md="4"
                    v-for="(pro, i) in item.products"
                    :key="i"
                  >
                    <v-text-field
                      :rules="[
                        (v) => !!v || 'חובה',
                        () => !pro.err || 'ברקוד לא קיים'
                      ]"
                      v-model="pro.barcode"
                      outlined
                      dense
                      @keydown="pro.err = false"
                    >
                      <template slot="label">
                        <strong class="red--text">*</strong>
                        ברקוד
                      </template>
                    </v-text-field>
                    <v-btn
                      @click="item.products.splice(i, 1)"
                      icon
                      color="error"
                      v-if="i != 0"
                    >
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </v-col>
                  <v-btn
                  v-if="(this.$route.params.id
          ? this.$store.getters.permission('blocks edit')
          : this.$store.getters.permission('blocks create'))"
                    @click="item.products.push({ barcode: '', err: false })"
                    outlined
                    color="primary"
                    >מוצר חדש</v-btn
                  >
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="justify-end px-4">
            <div class="d-flex">
              <v-btn
                @click="close_form()"
                x-large
                depressed
                height="40"
                color="black--text"
              >
                <v-icon>arrow_forward</v-icon>
              </v-btn>
              <v-btn
              v-if="(this.$route.params.id
            ? this.$store.getters.permission('blocks edit')
            : this.$store.getters.permission('blocks create'))"
                @click="save()"
                :loading="save_loader"
                :disabled="save_loader"
                x-large
                depressed
                height="40"
                color="black white--text ms-3"
                >שמירה</v-btn
              >
            </div>
          </v-card-actions>
        </v-card>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Form",
  data() {
    return {
      save_loader: false,
      name_exist: false,
      item: {
        name: "",
        type: "classic",
        status: true,
        sort: null,
        products_type: "random",
        count: null,
        products: [
          {
            barcode: "",
            err: false,
          },
        ],
        sections: [],
      },
      sections: [],
      products_types: [
        {
          value: "random",
          text: "אקראי",
        },
        {
          value: "new",
          text: "לפי חדש",
        },
        {
          value: "most_sell",
          text: "הכי נמכר",
        },
      ],
      types: [
        {
          value: "smart",
          text: "חכם",
        },
        {
          value: "classic",
          text: "קלאסיק",
        },
      ],
    };
  },
  methods: {
    close_form() {
      if (this.save_loader) {
        this.$emit("update_table");
        this.save_loader = false;
      }
      this.$emit("close_form");
    },

    get_sections() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "sections",
            method: "get",
          },
        })
        .then((res) => {
          
          this.sections = Object.assign([], res.data.data);
        });
    },
    edit() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: `blocks/${this.$route.params.id}`,
            method: "get",
          },
        })
        .then((res) => {
          
          this.item = Object.assign({}, res.data.data);
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    save() {
      if (
        this.$refs.form.validate() &&
        !this.save_loader &&
        (this.$route.params.id
          ? this.$store.getters.permission("blocks edit")
          : this.$store.getters.permission("blocks create"))
      ) {
        this.save_loader = true;
        this.$store
          .dispatch("public__request", {
            config: {
              url: this.$route.params.id
                ? `blocks/${this.$route.params.id}`
                : "blocks",
              method: this.$route.params.id ? "put" : "POST",
            },
            data: this.item,
          })
          .then((res) => {
            
            this.$router.push("/blocks");
            this.close_form();
          })
          .catch((err) => {
            this.save_loader = false;
            let errors = err.response.data.errors;

            Object.entries(errors).forEach((ev, i) => {
              if (ev[1][0].includes("barcode is invalid")) {
                this.$set(this.item.products[i], "err", true);
              }
            });

            this.$refs.form.validate();
          });
      }
    },
  },
  mounted() {
    this.get_sections();
    if (this.$route.params.id) {
      this.edit();
    }
  },
};
</script>
